import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import { Typography, Container } from "@material-ui/core"
const useStyles = makeStyles(theme => ({
  spacer: {
    height: "15vh",
  },
  header: {
    fontSize: "2rem",
    fontFamily: "Jost",
    color: "white",
    textAlign: "center",
  },
  subheader: {
    fontSize: "1.5rem",
    fontFamily: "Jost",
    color: "white",
    textAlign: "center",
  },
}))
export default function NotFound() {
  const classes = useStyles()
  return (
    <Layout>
      <div className={classes.spacer} />
      <Container>
        <Typography className={classes.header}>
          Error 404: Page not found.
        </Typography>
        <Typography className={classes.subheader}>
          You may have entered the URL incorrectly, or tried to go to a page
          that doesn't exist.
        </Typography>
      </Container>
    </Layout>
  )
}
